.custom-bootstrap{
    //importing bootstrap in here to avoid bootstrap css elements affect other 
    @import "~bootstrap/scss/bootstrap";

    p.c-line-none, p.c-line-del, p.c-line-add {
        margin-bottom: 0;
    }
    .c-json-string {
        color: black;
    }
}