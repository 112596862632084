.MuiPaper-root > .MuiAutocomplete-listbox > li > .MuiAutocomplete-groupUl:has(li.customDropDownSelector) {
    width:750px !important;
}

.Custom-option-category {
    top: -8px;
    background-color: #fff;
    top: 0;
    z-index: 1;
    position: sticky;
    padding-left: 16px;
    padding-right: 16px;
    color: rgba(0, 0, 0, 0.54);
    font-size: 0.875rem;
    box-sizing: border-box;
    list-style: none;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 500;
    line-height: 48px;
}

.MuiPaper-root > .MuiAutocomplete-listbox {
    padding: 0;
}