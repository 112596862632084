body {
  margin: 0;
  background-color: #efefef;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
}

a {
  text-decoration: none;
  color: #170F4F;
}

form {
  width: 100%;
}

.text-right {
  text-align: right;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.padding-10 {
  padding: 10px;
}

.padding-bottom-10 {
  padding-bottom: 10px;
}

.margin-top-10 {
  margin-top: 10px;
}

.margin-top-20 {
  margin-top: 20px;
}

.margin-left-10 {
  margin-left: 10px !important;
}

.margin-right-10 {
  margin-right: 10px !important;
}

.margin-bottom-10 {
  margin-bottom: 10px;
}

.margin-bottom-20 {
  margin-bottom: 20px;
}

.width-40 {
  width: 40%;
}

.width-100 {
  width: 100%;
  box-sizing: border-box;
}

.float-right {
  float: right;
}

.background-transparent {
  background: transparent;
}

.font-weight-bold {
  font-weight: bold;
}

.display-value {
  font-size: 1rem;
  font-weight: bold;
  margin: 0;
}

.select {
  padding: 18px 14px;
  color: rgba(0, 0, 0, 0.87);
  font-size: 1rem;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.23);
}

.select--small {
  padding-top: 10px;
  padding-bottom: 10px;
}

.select:focus {
  padding: 17px 13px;
  border: 2px solid #170F4F;
}

.select--small:focus {
  padding-top: 9px;
  padding-bottom: 9px;
  border: 2px solid #170F4F;
}

.input-field {
  padding: 10px 14px;
  color: rgba(0, 0, 0, 0.87);
  font-size: 1rem;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.23);
}

.display-flex {
  display: flex;
}

.justify-right {
  justify-content: right;
}

.justify-space-between {
  justify-content: space-between;
}

.align-items-center {
  align-items: center;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  background: #170F4F !important;
}

.grouping-header {
  color: #ACACAC
}

.grouping-content {
  padding-left: 20px;
}

.vertical-align-top {
  vertical-align: top !important;
}

.link-disabled {
  pointer-events: none;
  color: rgba(0, 0, 0, 0.26);
}
.link-disabled {
  pointer-events: none;
  color: rgba(0, 0, 0, 0.26);
}
.cellLink-disabled {
  pointer-events: none;
}

.authentication-form {
  padding: 20px;
  margin: auto;
  min-width: 320px;
  max-width: 400px;
}

.authentication-cover {
  min-height: 100vh;
  padding: 5% 0;
  background: url('assets/images/login-background_bolttech.jpg') no-repeat center center fixed;
  background-size: cover;
  box-sizing: border-box;
  display: flex;
}

.authentication-error-message {
  background: red !important;
  padding: 10px;
  margin-bottom: 20px;
  color: #FFFFFF !important;
  font-weight: bold
}

.word-break-all {
  word-break: break-all;
  word-break: break-word;
}

.toolbar {
  display: block !important;
}

.display-block {
  display: block;
}

.display-none {
  display: none;
}

.active {
  color: #E3D900 !important
}

.color-danger {
  color: red;
}

.color-success {
  color: #008000FF !important;
}

.font-size-1 {
  font-size: 1rem !important;
}

@media (min-width: 992px) {
  .display-desktop-flex {
    display: flex;
  }

  .active {
    background: #E3D900;
    color: #170F4F !important
  }
}